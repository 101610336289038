import React, { Suspense, lazy, useCallback, useMemo, useState } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import GlobalLoader from 'containers/common/loaders/GlobalLoader';
import ConfirmationModal from 'containers/pages/projectDetails/components/ConfirmationModal';
import { GlobalContext } from 'context/GlobalContext';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';

// AUTH PAGES
const LoginPage = lazy(() => import('containers/pages/login'));
const ForgotPassword = lazy(() => import('containers/pages/forgotPassword'));
const ResetPassword = lazy(() => import('containers/pages/resetPassword'));

// PRIVATE PAGES
const Requests = lazy(() => import('containers/pages/requests'));
const Documents = lazy(() => import('containers/pages/documents'));
const ProjectDetails = lazy(() => import('containers/pages/projectDetails'));
const SettingsPage = lazy(() => import('containers/pages/settings'));
const Messenger = lazy(() => import('containers/pages/messenger'));
const Calendar = lazy(() => import('containers/pages/calendar'));
const Contacts = lazy(() => import('containers/pages/contacts'));
const Profile = lazy(() => import('containers/pages/profile'));
const SetPassword = lazy(() => import('containers/pages/setPassword'));
const PendingPasswordRequests = lazy(() => import('containers/pages/pendingRequests'));

function AppRoutes() {
  const [showModal, setShowModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [nextPath, setNextPath] = useState(null);

  const handleToggleDialog = useCallback(() => setShowModal(prevState => !prevState), []);

  const contextValue = useMemo(
    () => ({
      showModal,
      setShowModal,
      selectedProject,
      setSelectedProject,
      nextPath,
      setNextPath,
    }),
    [showModal, selectedProject]
  );

  return (
    <GlobalContext.Provider value={contextValue}>
      <BrowserRouter>
        <Suspense fallback={<GlobalLoader />}>
          <Routes>
            <Route path="/">
              <Route path="auth" element={<PublicRoutes />}>
                <Route path="login" element={<LoginPage />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset-password" element={<ResetPassword />} />
              </Route>

              <Route path="/" element={<PrivateRoutes />}>
                <Route index element={<Requests />} />

                <Route path="requests" element={<Outlet />}>
                  <Route index element={<Requests />} />
                  <Route path=":id" element={<ProjectDetails />} />
                </Route>

                <Route path="settings" element={<SettingsPage />} />
                <Route path="messenger" element={<Messenger />} />
                <Route path="calendar" element={<Calendar />} />
                <Route path="documents" element={<Documents />} />
                <Route path="contacts" element={<Contacts />} />
                <Route path="profile/:id" element={<Profile />} />
                <Route path="pending-access-requests" element={<PendingPasswordRequests />} />
                <Route path="set-password" element={<SetPassword />} />
              </Route>
            </Route>
          </Routes>
        </Suspense>

        {showModal && (
          <ConfirmationModal open={showModal} onClose={handleToggleDialog} onConfirm={handleToggleDialog} />
        )}
      </BrowserRouter>
    </GlobalContext.Provider>
  );
}

export default AppRoutes;

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from 'context/GlobalContext';

function ConfirmationModal({ open, onClose }) {
  const navigate = useNavigate();
  const { nextPath } = useGlobalContext();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Completion</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to leave without completeing below task</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: '15px' }}>
        <Button onClick={onClose} color="primary">
          No
        </Button>
        <Button
          onClick={() => {
            try {
              navigate(nextPath ?? '/');
              onClose();
            } catch (error) {
              console.error('Navigation error:', error);
            }
          }}
          color="primary"
          variant="contained"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationModal.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default ConfirmationModal;

import { createContext, useContext } from 'react';

export const GlobalContext = createContext({
  showModal: false,
  setShowModal: () => {},
  selectedProject: null,
  setSelectedProject: () => {},
});

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);

  return context;
};

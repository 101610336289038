import React from 'react';
import { Box, List } from '@mui/material';
import { useSelector } from 'react-redux';
import { Dashboard, Folder, Settings, Event, Chat, Contacts, LockClock } from '@mui/icons-material';

// COMPONENTS & STYLES
import styles from 'styles/common/layout.module.scss';
import theme from 'styles/mui/theme';
import SidebarItem from './SidebarItem';

function Sidebar() {
  const { messagesCount, logo } = useSelector(state => state.auth);

  return (
    <Box className={styles.sidebarContainer} sx={{ backgroundColor: theme.palette.themeDark.main }}>
      <Box className={styles.logo}>
        <img className={styles.styles} src={logo} alt="Logo" />
      </Box>
      <List>
        <SidebarItem icon={<Dashboard />} text="Task Vault" path="/" />
        <SidebarItem icon={<Folder />} text="Documents" path="/documents" />
        <SidebarItem icon={<Event />} text="Calendar" path="/calendar" />
        <SidebarItem icon={<Chat />} text="Bookkeeper Hotline" path="/messenger" count={messagesCount} />
        <SidebarItem icon={<Contacts />} text="Contacts" path="/contacts" />
        <SidebarItem icon={<LockClock />} text="Pending Access Requests" path="/pending-access-requests" />
        <SidebarItem icon={<Settings />} text="Settings" path="/settings" />
      </List>
    </Box>
  );
}

export default Sidebar;

import React, { useCallback, useState } from 'react';
import { Avatar, Box, Divider, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { onLoggedOut } from 'store/slices/authSlice';
import useGetUserData from 'customHooks/useGetUserData';
import { useGlobalContext } from 'context/GlobalContext';

// STYLES
import styles from 'styles/common/layout.module.scss';

function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profileMenu, setProfileMenu] = useState(false);
  const { firstName, lastName, userId, avatar, email } = useGetUserData();
  const { setShowModal, selectedProject, setNextPath } = useGlobalContext();

  const handleOpenMenu = useCallback(e => {
    setProfileMenu(e.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(
    (path = null) => () => {
      if (selectedProject?.length === 0) {
        navigate(path);
        return;
      }

      const isInProgressTask = selectedProject?.some(task => task.status === 'waiting_on_client');

      if (isInProgressTask) {
        setShowModal(true);
        if (path) {
          setNextPath(path);
        }
        setProfileMenu(null);
        return;
      }

      if (path) {
        navigate(path);
      }

      setProfileMenu(null);
    },
    [selectedProject, navigate, setNextPath, setShowModal]
  );

  const handleLogout = useCallback(() => {
    setProfileMenu(null);
    dispatch(onLoggedOut());
  }, []);

  return (
    <Box p={2} className={styles.navbarContainer}>
      <Box />
      <Stack
        className="pointer"
        direction="row"
        alignItems="center"
        sx={{
          gap: {
            xs: 1,
            sm: 2,
          },
        }}
        onClick={handleOpenMenu}
      >
        <div className="flex-column align-items-end d-md-flex">
          <Typography component="span" className="d-flex fw-semibold text-capitalize">
            {firstName} {lastName}
          </Typography>
          <Typography className="d-none d-md-inline" color="text.secondary">
            {email}
          </Typography>
        </div>
        <Avatar
          src={avatar || '/'}
          sx={{
            height: { xs: 40, sm: 50 },
            width: { xs: 40, sm: 50 },
          }}
          alt={firstName}
        />
      </Stack>

      <Menu
        key={profileMenu}
        open={!!profileMenu}
        anchorEl={profileMenu}
        onClose={handleCloseMenu()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ '& .MuiPaper-root': { minWidth: '120px', marginTop: '10px' } }}
      >
        <MenuItem className="justify-content-center" onClick={handleCloseMenu(`/profile/${userId}`)}>
          Profile
        </MenuItem>

        <Divider className="my-1" />

        <MenuItem className="justify-content-center" onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default Navbar;

import React from 'react';
import { ListItemButton, ListItemIcon, ListItemText, Badge } from '@mui/material';
import propTypes from 'prop-types';
import { Link, useMatch, useNavigate } from 'react-router-dom';

import styles from 'styles/common/layout.module.scss';
import { useGlobalContext } from 'context/GlobalContext';

function SidebarItem({ icon, text, path, count = 0 }) {
  const isActive = useMatch(path);
  const navigate = useNavigate;
  const { setShowModal, selectedProject, setNextPath } = useGlobalContext();

  const isInProgressTask = selectedProject?.some(task => task.status === 'waiting_on_client');

  const handleClick = e => {
    if (selectedProject.length > 0 && isInProgressTask) {
      e.preventDefault();
      if (isInProgressTask) {
        setNextPath(path);
        setShowModal(true);
      }
    } else {
      navigate(path);
    }
  };

  return (
    <ListItemButton
      component={Link}
      to={!isInProgressTask ? path : undefined}
      selected={!!isActive}
      onClick={isInProgressTask ? handleClick : undefined}
      sx={{
        transition: 'all .35s ease',
        backgroundColor: isActive ? 'rgba(255, 255, 255, 0.15) !important' : 'transparent',
        '&:hover': { backgroundColor: isActive ? '' : 'rgba(255, 255, 255, 0.05) !important' },
      }}
    >
      <ListItemIcon sx={{ minWidth: '40px', color: 'white' }} className={styles.listItemIcon}>
        <Badge badgeContent={count} color="primary">
          {icon}
        </Badge>
      </ListItemIcon>

      <ListItemText className="d-none d-md-block" primary={text} />
    </ListItemButton>
  );
}

SidebarItem.propTypes = {
  icon: propTypes.element.isRequired,
  text: propTypes.string.isRequired,
  path: propTypes.string.isRequired,
  count: propTypes.number,
};

export default SidebarItem;
